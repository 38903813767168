import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../../components/PageWrapper";
import styled from "styled-components";
import SEO from "../../../components/seo";
import BackButton from "../../../components/Core/BackButton";
import PageTitle from "../../../components/Core/PageTitle";
import NoAuthRedirect from "../../../components/Core/NoAuthRedirect";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const Content = styled.div`

    $ a:hover {
        color: silver;
    }

    & h2 {
        margin: 1.5rem 0;
    }
`

const BlogArticle = ({data, location}) => {
    const headerConfig = {
        paddingBottom: 10,
    };

    return (
        <>  
            <UnauthenticatedTemplate>
                <NoAuthRedirect location={location} />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageWrapper headerConfig={headerConfig}>
                    <SEO title="Recent experience - Pei Lin Pan" />
                    <Content>
                        <BackButton />
                        <Container className="">
                            <Row className="">
                                <Col className="col-lg-2"></Col>
                                <Col className="col-lg-6">
                                    <PageTitle
                                        title="Pei Lin Pan shares her experience"
                                        align="left"
                                    />
                                </Col>
                            </Row>
                            <Row className="">
                                <Col className="col-lg-2 text-right">
                                    <div className="mb-4 border-top pt-7">
                                        {/* Recent experience */}
                                    </div>
                                    <div>
                                        {/* Last updated<br /> */}
                                        {/* 2 Jan, 2022 */}
                                    </div>
                                </Col>
                                <Col className="col-lg-6">
                                    <StaticImage
                                        src="photo_pei_lin_pan_portrait_2.jpg"
                                        alt="Portrait of Pei Lin Pan - smiling"
                                        placeholder="blurred"
                                        layout="fixed"
                                        quality={100}
                                        width={480}
                                        style={{maxWidth: '100%'}}
                                        className="mb-10"
                                    />
                                    <p>
                                        My name is Pei Lin Pan and I currently work as M&A Manager in the COFRA Holding strategy team. I started at COFRA back in 2017 at Anthos Fund & Asset Management, where I worked in risk and business analytics before moving to investments analytics (liquid assets).  
                                    </p>
                                    <p>
                                        After almost 5 wonderful years at Anthos Fund and Asset Management, an opportunity came up last year to do a 6-month assignment within the Holding Strategy team. With the support of my manager, as the assignment would mean I needed to work 50/50, I was able to start my strategy assignment in November 2021. I was really looking forward to being exposed to a dynamic, fast paced environment where I would be able to experience new learnings - and I got so much more than I asked for! Although I could rely on my previous knowledge and experience for some aspects of the assignment, the even more exciting part was that I was exposed to so many new stimuli and learning opportunities.  
                                    </p>
                                    <StaticImage
                                        src="photo_pei_lin_pan_in_nature.jpg"
                                        alt="Portrait of Pei Lin Pan - smiling"
                                        placeholder="blurred"
                                        layout="fixed"
                                        quality={100}
                                        width={480}
                                        style={{maxWidth: '100%'}}
                                        className="mb-10"
                                    />
                                    <p>
                                        During my assignment, I supported the Strategy team to conduct due diligence on potential deals, with doing market research and analyses, and I was also able to experience how an M&A process evolves week by week, or even day by day. Everything moved at a very fast pace and the project and stakeholder management part of it was absolutely fascinating. The six months with the team flew by and yet I felt there was still so much I wanted to learn, so I was quite over the moon when I heard that there was a vacancy opening in the team. After good talks between the COFRA Strategy team and my manager at Anthos Fund and Asset Management, I was able to successfully transfer to COFRA Holding in early 2022.  
                                    </p>
                                    <p>
                                        Since then, my life has been a constant stream of new experiences and adventures. I am learning a lot in my new role and have had to learn many new things that were less emphasized in my previous position. At times this may feel uncomfortable, but as a to myself reminder, I know these are exactly the times when we grow and learn the most, so it is important to embrace these feelings and thoughts.    
                                    </p>
                                    <p>
                                        Looking back, I am very thankful for the opportunity to have this experience and to follow this path to my current role. There is so much knowledge and experience and so many different voices and thoughts within the organization, that it would almost feel like a loss if we didn’t explore these opportunities for sharing, collaboration - and for our own development.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Content>
                </PageWrapper>
            </AuthenticatedTemplate>
        </>
    )
}

export default BlogArticle;
